import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container } from "../components/Container"
import logosrc from "../assets/images/logo_footer.png"

const FooterWrapper = styled.footer`
  padding: ${props => props.theme.spacing[10]} 0;
  background: ${props => props.theme.colors.brand.gray};
`

const InnerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logotype = styled(Link)`
  display: inline-flex;
  flex: 0 0 50%;

  img {
    width: 100px;
  }

  @media (min-width: 600px) {
    img {
      width: 120px;
    }
  }

  @media (min-width: 1000px) {
    flex: 1 0 auto;
    img {
      width: 140px;
    }
  }
`

const LinkWrapper = styled.div`
  flex: 0 0 50%;

  @media (min-width: 1000px) {
    flex: 1 0 auto;
    text-align: right;
    a {
      margin: 0 ${props => props.theme.spacing[2]};
    }

    a:first-child {
      margin-left: 0;
    }

    a:last-child {
      margin-right: 0;
    }
  }
`

const CompanyLink = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  :hover,
  :focus,
  :active {
    text-decoration: underline;
  }

  svg {
    position: relative;
    top: -2px;
    stroke: ${props => props.theme.colors.brand.orange};
  }
`
const CompanyLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  )
}

export const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <InnerFooter>
          <Logotype to="/">
            <img src={logosrc} alt="Advania Sverige AB" />
          </Logotype>
          <LinkWrapper>
            <CompanyLink
              href="https://advania.se"
              rel="noopener noreferrer"
              title="Advania Sverige AB"
            >
              <CompanyLinkIcon />
              Advania Sverige
            </CompanyLink>
            <CompanyLink
              href="https://advania.is"
              rel="noopener noreferrer"
              title="To Advania Iceland"
            >
              <CompanyLinkIcon />
              Advania Iceland
            </CompanyLink>
            <CompanyLink
              href="https://advania.no"
              rel="noopener noreferrer"
              title="To Advania Norway"
            >
              <CompanyLinkIcon />
              Advania Norway
            </CompanyLink>
            <CompanyLink
              href="https://advania.fi"
              rel="noopener noreferrer"
              title="To Advania Finland"
            >
              <CompanyLinkIcon />
              Advania Finland
            </CompanyLink>
            <CompanyLink
              href="https://advania.dk"
              rel="noopener noreferrer"
              title="To Advania.dk"
            >
              <CompanyLinkIcon />
              Advania Denmark
            </CompanyLink>
            <CompanyLink
              href="https://advania.com"
              rel="noopener noreferrer"
              title="To Advania.com"
            >
              <CompanyLinkIcon />
              Advania.com
            </CompanyLink>
          </LinkWrapper>
        </InnerFooter>
      </Container>
    </FooterWrapper>
  )
}
