export const Theme = {
  colors: {
    white: "#fefefe",
    black: "#333333",
    gray: [
      "#F7FAFC",
      "#EDF2F7",
      "#E2E8F0",
      "#CBD5E0",
      "#A0AEC0",
      "#718096",
      "#4A5568",
      "#2D3748",
      "#1A202C",
    ],
    brand: {
      purple: "#98139C",
      orange: "#F38901",
      green: "#84A919",
      blue: "#2ABAD9",
      gray: "#565656",
    },
  },
  spacing: [
    "0",
    ".25rem",
    ".5rem",
    ".75rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3rem",
    "4rem",
    "5rem",
    "6rem",
    "8rem",
    "10rem",
    "12rem",
    "14rem",
    "16rem",
  ],
  fontSize: [
    "0.8rem",
    "1rem",
    "1.25rem",
    "1.563rem",
    "1.953rem",
    "2.441rem",
    "3.052rem",
  ],
  shadows: {
    content: "0 6px 20px 0 rgba(0, 0, 0, 0.08)",
    light: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    medium:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    large:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  },
}
