import styled from "styled-components"

export const SecondLevelHeader = styled.h2`
  font-size: 2.4rem;

  @media (min-width: 600px) {
    font-size: 3rem;
  }
`

export const ThirdLevelHeader = styled.h3`
  font-size: 1.8rem;
  text-align: ${props => (props.center ? "center" : "left")};

  @media (min-width: 600px) {
    font-size: 2rem;
  }
`
