import { createGlobalStyle } from "styled-components"
import * as normalize from "normalize.css"

export const GlobalStyles = createGlobalStyle`
    @font-face {
    font-family: 'AdelleSans-Regular';
    src: url('../../fonts/AdelleSans-Regular.svg#AdelleSans-Regular') format('svg'),
         url('../../fonts/AdelleSans-Regular.ttf') format('truetype'),
         url('../../fonts/AdelleSans-Regular.woff') format('woff'),
         url('../../fonts/AdelleSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'AdelleSans-Bold';
    src: url('../../fonts/AdelleSans-Bold.svg#AdelleSans-Bold') format('svg'),
         url('../../fonts/AdelleSans-Bold.ttf') format('truetype'),
         url('../../fonts/AdelleSans-Bold.woff') format('woff'),
         url('../../fonts/AdelleSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Adelle-Regular';
    src: url('../../fonts/Adelle-Regular.svg#Adelle-Regular') format('svg'),
         url('../../fonts/Adelle-Regular.ttf') format('truetype'),
         url('../../fonts/Adelle-Regular.woff') format('woff'),
         url('../../fonts/Adelle-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Adelle-ExtraBold';
    src: url('../../fonts/Adelle-ExtraBold.svg#Adelle-ExtraBold') format('svg'),
         url('../../fonts/Adelle-ExtraBold.ttf') format('truetype'),
         url('../../fonts/Adelle-ExtraBold.woff') format('woff'),
         url('../../fonts/Adelle-ExtraBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Adelle-Bold';
    src: url('../../fonts/Adelle-Bold.svg#Adelle-Bold') format('svg'),
         url('../../fonts/Adelle-Bold.ttf') format('truetype'),
         url('../../fonts/Adelle-Bold.woff') format('woff'),
         url('../../fonts/Adelle-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }

    ${normalize}

    html {
        box-sizing: border-box;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${props => props.theme.colors.black};
        font-family: 'AdelleSans-Regular';
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    html,
    body,
    #___gatsby,
    #___gatsby > div {
        /* height: 100%; */
        
    }

    #___gatsby > div {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Adelle-Bold';
        color: #333;
    }

`
