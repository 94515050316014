import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { Container } from "../components/Container"

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "audience.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="div" className={className} fluid={imageData}>
          {children}
        </BackgroundImage>
      )
    }}
  />
)

const FullWidthWrap = styled.section`
  /* margin-bottom: ${props => props.theme.spacing[14]}; */
`

const InnerWrap = styled(BackgroundSection)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media (min-width: 600px) {
    height: 700px;
  }
`
const Overlay = styled.div`
  background: rgba(152, 19, 156, 0.25);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const CTAContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 1;
`

const CTAHeader = styled.h2`
  font-size: 3rem;
  font-family: "Adelle-ExtraBold";
  color: ${props => props.theme.colors.white};

  line-height: 1.4;
  margin: 0 0 ${props => props.theme.spacing[7]};

  span {
    color: #f98e05;
  }

  @media (min-width: 600px) {
    font-size: 4rem;
  }
`

const CTAButton = styled.a`
  border-radius: 2px;
  background: ${props => props.theme.colors.brand.orange};
  padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[7]};
  font-size: 1.4rem;
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  flex: 1 0 auto;
  font-weight: bold;
  box-shadow: ${props => props.theme.shadows.medium};
  transform: translateY(0);
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  will-change: transform, transition;

  :hover,
  :focus,
  :active {
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.shadows.large};
  }
`

export const PeopleHunt = () => {
  return (
    <FullWidthWrap>
      <InnerWrap>
        <Overlay />
        <Container>
          <CTAContent>
            <CTAHeader>
              Vi letar <span>alltid</span> efter grymma medarbetare. <br />
              Nyfiken?
            </CTAHeader>
            <CTAButton
              href="https://jobb.advania.se/jobs?department_id=24531"
              rel="noopener noreferrer"
              title="Advania Sveriges sida för lediga jobb inom Software Solutions"
            >
              Se lediga jobb
            </CTAButton>
          </CTAContent>
        </Container>
      </InnerWrap>
    </FullWidthWrap>
  )
}
