import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import { Theme } from "../styles/theme"
import { GlobalStyles } from "../styles/global"

import { Header } from "./Header"
import { Footer } from "./Footer"

const ContentWrapper = styled.div`
  flex: 1;
`

const MainContent = styled.main`
  /* height: 100%; */
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyles />
      <Header />
      <ContentWrapper>
        <MainContent>{children}</MainContent>
      </ContentWrapper>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
