import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import { SecondLevelHeader } from "./SectionHeader"
import { Container } from "./Container"

import image from "../images/henke.jpg"

const Wrapper = styled.section`
  /* margin-bottom: ${props => props.theme.spacing[14]}; */
  
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const Left = styled.div`
  flex: 0 0 50%;
  padding: ${props => props.theme.spacing[12]} 0;

  p {
    line-height: 1.6;
    font-size: 1.4rem;
    margin-bottom: ${props => props.theme.spacing[10]};
  }

  @media (min-width: 800px) {
    padding: ${props => props.theme.spacing[14]} 0;
  }

  @media (min-width: 1000px) {
    padding-right: ${props => props.theme.spacing[7]};
  }
`

const Right = styled.div`
  flex: 0 0 50%;
  padding: ${props => props.theme.spacing[7]} ${props => props.theme.spacing[4]};
  /* background: rgba(243, 137, 1, 0.3); */
  background: ${props => props.theme.colors.brand.gray};

  @media (min-width: 600px) {
    padding: ${props => props.theme.spacing[10]}
      ${props => props.theme.spacing[7]};
  }
`

const Form = styled.form`
  border-radius: 4px;
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing[7]} ${props => props.theme.spacing[4]};
  width: 100%;
  box-shadow: ${props => props.theme.shadows.content};

  @media (min-width: 600px) {
    padding: ${props => props.theme.spacing[7]};
  }
`

const InputWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing[4]};
  position: relative;
`

const Label = styled.label`
  display: inline-block;
  margin-bottom: ${props => props.theme.spacing[1]};

  span {
    color: ${props => props.theme.colors.gray[6]};
  }
`

const Input = styled.input`
  display: block;
  width: 100%;
  background: ${props => props.theme.colors.gray[0]};
  border: 0;
  padding: ${props => props.theme.spacing[3]} ${props => props.theme.spacing[4]};
  border-bottom: 1px solid ${props => props.theme.colors.gray[3]};
  border-radius: 2px;

  :focus,
  :active {
    background: ${props => props.theme.colors.gray[1]};
  }
`

const Textarea = styled.textarea`
  width: 100%;
  background: ${props => props.theme.colors.gray[0]};
  border: 0;
  padding: ${props => props.theme.spacing[3]} ${props => props.theme.spacing[4]};
  border-bottom: 1px solid ${props => props.theme.colors.gray[3]};
  min-height: 120px;
  resize: vertical;
  border-radius: 2px;

  :focus,
  :active {
    background: ${props => props.theme.colors.gray[1]};
  }
`

const Checkbox = styled.input`
  height: 30px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 30px;

  + label {
    padding-left: calc(30px + 1rem);

    margin: 0;
    color: ${props => props.theme.colors.gray[6]};

    @media (min-width: 1400px) {
      padding-top: 0.5rem;
    }
  }

  + label:before {
    border: 2px solid ${props => props.theme.colors.gray[3]};
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
    border-radius: 2px;
  }

  &:focus + label:before {
    outline-color: rgb(77, 144, 254);
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
  }

  + label::after {
    content: "";
    background: ${props => props.theme.colors.brand.purple};
    height: 24px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transition: opacity 0.2s ease-in-out;
    width: 24px;
    border-radius: 2px;
  }

  &:checked + label::after {
    opacity: 1;
  }
`

const SubmitButton = styled.button`
  background: ${props => props.theme.colors.brand.purple};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border: 0;
  box-shadow: ${props => props.theme.shadows.medium};
  border-radius: 2px;
  transform: translateY(0);
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  padding: ${props => props.theme.spacing[3]} ${props => props.theme.spacing[4]};
  cursor: pointer;
  margin-top: ${props => props.theme.spacing[7]};

  @media (min-width: 1000px) {
    padding: ${props => props.theme.spacing[4]}
      ${props => props.theme.spacing[7]};
  }

  :hover,
  :focus,
  :active {
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.shadows.large};
  }

  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const ThankYou = styled.div`
  border: 2px solid ${props => props.theme.colors.brand.green};
  margin-top: ${props => props.theme.spacing[4]};
  padding: ${props => props.theme.spacing[7]} ${props => props.theme.spacing[4]};
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
`

const ContactPerson = styled.div`
  display: flex;
  align-items: center;
`

const ContactInfo = styled.div`
  > span,
  a {
    display: block;
    font-size: 1.2rem;
  }

  > span {
    margin-bottom: ${props => props.theme.spacing[7]};
    color: ${props => props.theme.colors.gray[6]};
  }

  > span:first-child {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: ${props => props.theme.spacing[2]};
    color: ${props => props.theme.colors.black};
  }

  a {
    color: ${props => props.theme.colors.black};
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;

    svg {
      margin-right: ${props => props.theme.spacing[2]};
      stroke: ${props => props.theme.colors.brand.orange};
    }

    :hover,
    :focus,
    :active {
      text-decoration: underline;
    }
  }
`

const ContactImage = styled.div`
  overflow: hidden;
  margin-right: ${props => props.theme.spacing[7]};
  box-shadow: ${props => props.theme.shadows.large};

  img {
    display: block;
    width: 120px;
    height: 120px;
  }

  @media (min-width: 400px) {
    img {
      width: 150px;
      height: 150px;
    }
  }
`

const TelephoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-phone"
  >
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  </svg>
)

export const Contact = () => {
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [mobilephone, setMobilephone] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [subject, setSubject] = useState("")
  const [consent, setConsent] = useState(false)

  const [messageSent, setMessageSent] = useState(false)
  const submitButtonRef = useRef()

  const encode = val => encodeURIComponent(val)

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `${encode("form-name")}=contact&firstname=${encode(
        firstname
      )}&lastname=${encode(lastname)}&mobile=${encode(
        mobilephone
      )}&email=${encode(email)}&company=${encode(company)}&subject=${encode(
        subject
      )}&consent=${encode(consent)}`,
    })
      .then(() => {
        setMessageSent(true)

        setFirstname("")
        setLastname("")
        setMobilephone("")
        setEmail("")
        setCompany("")
        setSubject("")
        setConsent(false)
      })
      .catch(error => console.log(error))

    e.preventDefault()
  }

  useEffect(() => {
    if (firstname && lastname && mobilephone && email && company && consent) {
      submitButtonRef.current.disabled = false
    } else {
      submitButtonRef.current.disabled = true
    }
  }, [firstname, lastname, mobilephone, email, company, consent])

  return (
    <Wrapper id="contact">
      <Container>
        <InnerWrapper>
          <Left>
            <SecondLevelHeader>Kontakta oss</SecondLevelHeader>
            <p>
              Har vi väckt ditt intresse? Kan vi fylla ditt behov? Tveka inte
              att höra av dig! Ring eller fyll i formuläret.
            </p>
            <ContactPerson>
              <ContactImage>
                <img src={image} alt="Bild på Henrik Stjernberg, konsultchef" />
              </ContactImage>
              <ContactInfo>
                <span>Henrik Stjernberg</span>
                <span>Consultant Manager</span>
                <a href="tel:+46761400337" title="Ring Henrik Stjernberg">
                  <TelephoneIcon />
                  <span>+46 761 40 03 37</span>
                </a>
              </ContactInfo>
            </ContactPerson>
          </Left>
          <Right>
            <Form
              onSubmit={handleSubmit}
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <InputWrapper>
                <Label htmlFor="contact_firstname">Förnamn</Label>
                <Input
                  type="text"
                  id="contact_firstname"
                  name="firstname"
                  required
                  value={firstname}
                  onChange={e => setFirstname(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="contact_lastname">Efternamn</Label>
                <Input
                  type="text"
                  id="contact_lastname"
                  name="lastname"
                  required
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="contact_mobilephone">Mobilnummer</Label>
                <Input
                  type="tel"
                  id="contact_mobilephone"
                  name="mobile"
                  required
                  placeholder="+46"
                  value={mobilephone}
                  onChange={e => setMobilephone(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="contact_email">E-post</Label>
                <Input
                  type="email"
                  id="contact_email"
                  name="email"
                  required
                  placeholder="ex. epostadress@företag.se"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="contact_company">Företag</Label>
                <Input
                  type="text"
                  id="contact_company"
                  name="company"
                  required
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="contact_subject">
                  Hur kan vi hjälpa till? <span>(Valfritt)</span>
                </Label>
                <Textarea
                  id="contact_subject"
                  name="subject"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                ></Textarea>
              </InputWrapper>
              <InputWrapper>
                <Checkbox
                  type="checkbox"
                  id="contact_consent"
                  name="consent"
                  required
                  checked={consent}
                  onChange={e => setConsent(prev => !prev)}
                />
                <Label htmlFor="contact_consent">
                  Jag godkänner att Advania sparar ovan angiven information om
                  mig.
                </Label>
              </InputWrapper>

              <input type="hidden" name="form-name" value="contact" />
              <SubmitButton
                type="submit"
                title="Skicka meddelandet"
                ref={submitButtonRef}
              >
                Skicka
              </SubmitButton>
              {messageSent && (
                <ThankYou>
                  <span>Tack, vi återkommer så fort vi kan!</span>
                </ThankYou>
              )}
            </Form>
          </Right>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}
