import React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"

import { Container } from "./Container"

const Wrapper = styled(Container)`
  display: flex;
  justify-content: flex-start;
  padding: 0 ${props => props.theme.spacing[4]};
  margin-bottom: ${props => props.theme.spacing[4]};

  /* @media (min-width: 480px) {
    justify-content: center;
  } */
`

const Inner = styled.div`
  a:first-child {
    margin-right: ${props => props.theme.spacing[4]};
  }

  @media (min-width: 1000px) {
    a:first-child {
      margin-right: ${props => props.theme.spacing[7]};
    }
  }
`

const CTA = styled(Link)`
  display: inline-block;
  text-decoration: none;
  padding: ${props => props.theme.spacing[3]} ${props => props.theme.spacing[4]};
  margin-bottom: ${props => props.theme.spacing[4]};
  border: 1px solid
    ${props =>
      props.primary
        ? props.theme.colors.brand.purple
        : props.theme.colors.gray[0]};
  color: ${props =>
    props.primary ? props.theme.colors.white : props.theme.colors.black};
  border-radius: 2px;
  font-weight: bold;
  box-shadow: ${props => props.theme.shadows.medium};
  transform: translateY(0);
  cursor: pointer;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  will-change: transform, box-shadow;
  background-color: ${props =>
    props.primary ? props.theme.colors.brand.purple : props.theme.colors.white};

  @media (min-width: 1000px) {
    padding: ${props => props.theme.spacing[4]}
      ${props => props.theme.spacing[7]};
    font-size: 1.4rem;
  }

  :hover,
  :focus,
  :active {
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.shadows.large};
  }
`

const CTARow = () => {
  return (
    <Wrapper>
      <Inner>
        {/* <CTA to="/">Se våra tjänster</CTA> */}
        <CTA
          primary={true ? 1 : 0}
          to="contact"
          smooth={true}
          duration={500}
          title="Gå till kontaktformuläret"
          href="#contact"
        >
          Kontakta oss
        </CTA>
      </Inner>
    </Wrapper>
  )
}

export default CTARow
