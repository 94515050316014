import React from "react"
import styled from "styled-components"

import { Container } from "./Container"

const PanelWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.spacing[7]};
  margin-bottom: ${props => props.theme.spacing[7]};

  @media (min-width: 600px) {
    padding-top: ${props => props.theme.spacing[10]};
    margin-bottom: ${props => props.theme.spacing[8]};
  }

  @media (min-width: 900px) {
    flex-direction: row;
    padding-top: ${props => props.theme.spacing[10]};
    margin-bottom: ${props => props.theme.spacing[10]};
  }

  @media (min-width: 1100px) {
    padding-top: ${props => props.theme.spacing[10]};
    margin-bottom: ${props => props.theme.spacing[10]};
  }

  @media (min-width: 1500px) {
    padding-top: ${props => props.theme.spacing[12]};
    margin-bottom: ${props => props.theme.spacing[12]};
  }

  @media (min-width: 2048px) {
    padding-top: ${props => props.theme.spacing[15]};
    margin-bottom: ${props => props.theme.spacing[12]};
  }
`

const Panel = styled.div`
  h1 {
    margin: 0;
    font-family: "Adelle-ExtraBold";
    letter-spacing: 0.01em;
  }

  span {
    display: block;
    line-height: 1.6;
    color: ${props => props.theme.colors.gray[8]};
    font-size: 1.2rem;
  }

  @media (min-width: 600px) {
    flex: 0 0 50%;

    h1 {
      font-size: 3rem;
    }

    span {
      font-size: 1.4rem;
    }
  }

  @media (min-width: 800px) {
    h1 {
      font-size: 4rem;
    }

    span {
      font-size: 1.6rem;
    }
  }

  @media (min-width: 1400px) {
    h1 {
      font-size: 5rem;
    }
  }

  @media (min-width: 2048px) {
    h1 {
      font-size: 6rem;
    }

    span {
      font-size: 2rem;
    }
  }
`

const LeftPanel = styled(Panel)`
  margin-bottom: ${props => props.theme.spacing[2]};

  @media (min-width: 900px) {
    margin-bottom: 0;
    padding-right: ${props => props.theme.spacing[4]};
  }
`

const RightPanel = styled(Panel)`
  @media (min-width: 900px) {
    padding-left: ${props => props.theme.spacing[4]};
  }
`

const IntroPanel = ({ heading, subheading }) => {
  return (
    <PanelWrapper>
      <LeftPanel>
        <h1>{heading}</h1>
      </LeftPanel>
      <RightPanel>
        <span>{subheading}</span>
      </RightPanel>
    </PanelWrapper>
  )
}

IntroPanel.defaultProps = {
  heading: `Teknik, kreativitet och know-how`,
  subheading: `Advania tar er från strategi till förverkligande i den digitala
  vardagen. Tillsammans driver vi den digitala evolutionen framåt – och
  gör skillnad.`,
}

export default IntroPanel
