import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"

const RectangleCanvas = styled.canvas`
  opacity: 0.3;

  @media (prefers-reduced-motion: reduce) {
    display: none;
  }
`

export const Canvas = () => {
  const canvasRef = useRef()
  //   const [canvasHeight, setCanvasHeight] = useState(300)
  const [rectCount, setRectCount] = useState(80)

  useEffect(() => {
    canvasRef.current.height = document.body.clientHeight / 1.5
    canvasRef.current.width = document.body.clientWidth
    const context = canvasRef.current.getContext("2d")

    const rectArray = []
    const colorArray = ["#98139C", "#F38901", "#84A919", "#2ABAD9"]

    const debounce = func => {
      let timer
      return event => {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(func, 100, event)
      }
    }

    const handleResize = debounce(() => {
      canvasRef.current.height = document.body.clientHeight / 1.5
      canvasRef.current.width = document.body.clientWidth

      init()
    })

    window.addEventListener("resize", handleResize)

    const init = () => {
      rectArray.length = 0
      for (let i = 0; i < rectCount; i++) {
        const side = Math.random() * 25 + 1
        const x = Math.random() * (document.body.clientWidth - side * 2) + side
        const y =
          Math.random() * (document.body.clientHeight / 1.5 - side * 2) + side
        const dx = (Math.random() - 0.5) * 0.6
        const dy = (Math.random() - 0.5) * 0.6

        rectArray.push(new Rectangle(x, y, dx, dy, side))
      }
    }

    const Rectangle = function(x, y, dx, dy, side) {
      this.x = x
      this.y = y
      this.dx = dx
      this.dy = dy
      this.side = side
      this.minSide = side
      this.color = colorArray[Math.floor(Math.random() * colorArray.length)]

      this.draw = function() {
        context.fillStyle = this.color
        context.fillRect(this.x, this.y, this.side, this.side)
        // context.strokeStyle = "black"
        // context.strokeRect(this.x, this.y, this.side, this.side)
      }

      this.update = function() {
        if (
          this.x + this.side > document.body.clientWidth ||
          this.x - this.side < 0
        ) {
          this.dx = -this.dx
        }

        if (
          this.y + this.side > document.body.clientHeight / 1.5 ||
          this.y - this.side < 0
        ) {
          this.dy = -this.dy
        }

        this.x += this.dx
        this.y += this.dy

        this.draw()
      }
    }

    const animate = () => {
      requestAnimationFrame(animate)
      context.clearRect(
        0,
        0,
        document.body.clientWidth,
        document.body.clientHeight / 1.5
      )

      for (let i = 0; i < rectArray.length; i++) {
        rectArray[i].update()
      }
    }

    init()
    animate()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [rectCount])

  return <RectangleCanvas ref={canvasRef}></RectangleCanvas>
}
