import React from "react"
import styled from "styled-components"

import { Container } from "./Container"

const Wrapper = styled.section``

const ReferenceContainer = ({ children }) => {
  return (
    <Container>
      <Wrapper id="references">{children}</Wrapper>
    </Container>
  )
}

export default ReferenceContainer
