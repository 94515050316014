import React from "react"
import styled, { keyframes } from "styled-components"
import { Link, animateScroll as scroll } from "react-scroll"

import { Canvas } from "./Canvas"
import IntroPanel from "./IntroPanel"
import CTARow from "./CTARow"

const HeroWrapper = styled.div`
  height: calc((85vh - 80px) - ${props => props.theme.spacing[4]});
  position: relative;

  @media (min-width: 480px) {
    height: calc((85vh - 80px) - ${props => props.theme.spacing[7]});
  }

  @media (min-width: 600px) {
    height: calc((85vh - 95px) - ${props => props.theme.spacing[7]});
  }

  @media (min-width: 1000px) {
    height: calc((85vh - 100px) - ${props => props.theme.spacing[7]});
    margin-bottom: ${props => props.theme.spacing[14]};
  }
`

const CanvasWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;

  @media (min-width: 1000px) {
    top: 0;
  }
`

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
`

const ScrollToLink = styled(Link)`
  position: absolute;
  left: 50%;
  bottom: ${props => props.theme.spacing[7]};
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${props => props.theme.spacing[4]};
  width: 100px;
  height: 100px;
  background: transparent;
  transition: background 0.25s ease;
  text-decoration: none;
  border-radius: 2px;

  span {
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.theme.colors.gray[7]};
    margin-bottom: ${props => props.theme.spacing[2]};
    transition: color 0.25s ease;
  }

  svg {
    width: 18px;
    height: 18px;
    stroke: ${props => props.theme.colors.brand.orange};
  }

  @media (min-width: 1400px) {
    width: 120px;
    height: 120px;
    bottom: ${props => props.theme.spacing[10]};

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 1rem;
    }
  }

  :hover,
  :focus,
  :active {
    cursor: pointer;
    background: ${props => props.theme.colors.brand.gray};

    span {
      color: ${props => props.theme.colors.white};
    }

    svg {
      animation: ${bounce} 1.8s ease-in-out infinite;
    }
  }
`

const Hero = ({ heading, subheading, goto, scrollTitle }) => {
  return (
    <HeroWrapper>
      <IntroPanel heading={heading} subheading={subheading} />
      <CTARow />
      <CanvasWrapper>
        <Canvas />
      </CanvasWrapper>

      <ScrollToLink
        to={goto}
        smooth={true}
        duration={500}
        offset={-120}
        title={`Läs mer om ${scrollTitle}`}
        href={`#${goto}`}
      >
        <span>Läs mer</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-down"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <polyline points="19 12 12 19 5 12"></polyline>
        </svg>
      </ScrollToLink>
    </HeroWrapper>
  )
}

Hero.defaultProps = {
  goto: `softwaresolutions`,
  scrollTitle: `Advania Software`,
}

export default Hero
