import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0 ${props => props.theme.spacing[4]};

  @media (min-width: 800px) {
    padding: 0 ${props => props.theme.spacing[7]};
  }

  @media (min-width: 1000px) {
    max-width: calc(1200px + ${props => props.theme.spacing[7]} * 2);
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
    max-width: calc(1400px + ${props => props.theme.spacing[7]} * 2);
  }

  @media (min-width: 2048px) {
    max-width: calc(1600px + ${props => props.theme.spacing[7]} * 2);
  }
`
