import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Container } from "./Container"
import logosrc from "../assets/images/advania-logo.svg"

const HeaderWrap = styled.header`
  margin-bottom: ${props => props.theme.spacing[4]};

  @media (min-width: 480px) {
    margin-bottom: ${props => props.theme.spacing[7]};
  }
`
const InnerHeader = styled.div`
    display: flex;
  align-items: center;
  height: 80px;

  @media (min-width: 600px) {
    height: 95px;
  }

  @media (min-width: 1000px) {
    height: 100px;
    /* padding: ${props => props.theme.spacing[4]}
      ${props => props.theme.spacing[7]}; */
  }
`

const Logotype = styled(Link)`
  display: inline-flex;

  img {
    width: 100px;
  }

  @media (min-width: 600px) {
    img {
      width: 120px;
    }
  }

  @media (min-width: 1000px) {
    img {
      width: 140px;
    }
  }

  @media (min-width: 2048px) {
    img {
      width: 160px;
    }
  }
`

const NavButton = styled.button`
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  margin-left: auto;
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  font-family: "AdelleSans-Bold";
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSize[0]};
  color: ${props => props.theme.colors.gray[7]};
  letter-spacing: 0.01em;

  & > span {
    display: inline-block;
    height: 9px;

    width: 20px;
    margin-left: ${props => props.theme.spacing[2]};
    position: relative;

    &:after,
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: ${props => props.theme.colors.brand.purple};
      left: 0;
    }

    &:before {
      top: 0;
      width: 75%;
      transition: width 0.25s ease;
      will-change: width;
    }

    &:after {
      bottom: 0;
    }
  }

  :hover,
  :focus,
  :active {
    & > span {
      &:before {
        width: 100%;
      }
    }
  }
`

export const Header = () => {
  return (
    <HeaderWrap>
      <Container>
        <InnerHeader>
          <Logotype to="/">
            <img src={logosrc} alt="Advania Sverige AB" />
          </Logotype>
          {/* <NavButton>
            Meny
            <span></span>
          </NavButton> */}
        </InnerHeader>
      </Container>
    </HeaderWrap>
  )
}
